@import url(https://fonts.googleapis.com/css?family=Nothing+You+Could+Do);

body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  background-size: cover;
  background-attachment:fixed;
  background-position:center bottom;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

.container {
  position: relative;
  width: 100%;
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  gap: 5px;
}

.comingSoon {
  width: 300px;
  margin-bottom: 30px;
  margin-top: 30px;
}

.timer {
  font-family: 'Nothing You Could Do';
  font-size: 30px;
  color: white;
  text-align: center;
  vertical-align: middle;
}

.timerLabel {
  font-family: 'Nothing You Could Do';
  font-size: 15x;
  margin-top: -30px;
  color: white;
  text-align: center;
  vertical-align: middle;
}

.timerLabel:not(:last-child) {
  margin-right: 15px;
}

.timerLabel:first-child {
  margin-left: 10px;
  padding-right: 20px;
}
